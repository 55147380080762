<template>
  <v-main v-if="lvl == 1">
    <v-container fluid class="text-right">
      <v-row>
        <v-col>
          <v-file-input label="파일 업로드" v-model="file"></v-file-input>
        </v-col>
        <v-col>
          <v-btn @click="upload_excel">xlsx 업로드</v-btn>
        </v-col>
        <v-col>
          <v-btn @click="delete_all">전체 삭제</v-btn>
        </v-col>
        <v-col>
          <v-dialog v-model="dialog" max-width="400px">
            <template v-slot:activator="{ on }">
              <v-btn class="mx-3 ma-0" color="white" fab v-on="on" elevation=0><v-icon color="#ab1d66" x-large>add</v-icon></v-btn>
            </template>
            <v-card>
              <v-card-text>
                <v-row dense>
                  <v-col cols="12">
                    <v-select
                      v-model="editedItem.type"
                      class="right-input"
                      :items="types"
                      label="음식 구분" />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="editedItem.name" class="right-input" label="식품명"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="editedItem.calory" class="right-input" label="칼로리"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="editedItem.carb" class="right-input" label="탄수화물 (g)"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="editedItem.fat" class="right-input" label="지방 (g)"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="editedItem.protein" class="right-input" label="단백질 (g)"></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" class="white--text" small @click="close">취소</v-btn>
                <v-btn color="blue darken-1" class="white--text" small @click="save">저장</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-card class="pa-3 ma-2 text-left" flat>
        <v-row dense>
          <v-col cols="3" >
            <div class="headline font-weight-bold pa-1 pb-0">음식</div>
            <div class="subtitle caption pa-1">
              <div class="desc_title">
                음식관리 관리자 화면
              </div>
            </div>
          </v-col>
          <v-col cols="9" >

            <v-container fluid v-if="loading">
              <v-row dense>
                <v-col cols="12">
                  <v-row class="align-center justify-center">
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
            <v-col v-else cols="12">
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                dense
                single-line
                hide-details
              ></v-text-field>
              <v-data-table
               :headers="food_headers"
                :search="search"
                :items="food_list"
                :mobile-breakpoint=NaN
                no-data-text="목록이 없습니다."
              >
                <template v-slot:item.action="{ item }">
                  <!--
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                  >
                    edit
                  </v-icon>
                  -->
                  <v-icon
                    small
                    @click="deleteItem(item)"
                  >
                    delete
                  </v-icon>
                </template>

              </v-data-table>
            </v-col>
          </v-col>
        </v-row>
      </v-card>
      <!--
      <v-row dense>
        <v-col md="12" xs="10">
          <v-row class="lighten-5 align-center justify-center">
            <v-card :key="1" elevation=0 flat width="75%">
              <v-toolbar flat dense color="#535a69">
                <span class="font-weight-bold white--text">음식 목록</span>
                <v-spacer />
                <v-toolbar-items>
                  <v-tooltip left nudge-bottom=80 >
                    <template v-slot:activator="{ on }">
                      <v-icon color="white" class="load" v-on="on">mdi-comment-question-outline</v-icon>
                    </template>
                    <span>
                      단백질(배수) 선택 가이드<br/>
                      1.6 : 일반적인 근육증가<br/>
                      2.0 : 저칼로리 식이, 초보자, 타고난사람<br/><br/>
                      - 체지방률 20% 초과인 경우<br/> 20%로 환산하어 계산합니다.
                    </span>
                  </v-tooltip>
                </v-toolbar-items>
              </v-toolbar>
              <v-divider />

            </v-card>
          </v-row>
        </v-col>
      </v-row>
      -->
    </v-container>
  </v-main>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';

import api_conf from '../../config/config.json';

import { mapGetters } from 'vuex';

import {
  read as xlsx_read,
  utils as xlsx_utils,
  writeFile as xlsx_write} from 'xlsx';


export default {
  props: {
    msg: String
  },
  data: () => {
    return {
      loading: true,
      dialog: false,
      search: "",
      food_list: [],
      food_headers: [
        { text: '구분', sortable: false, value: 'type', },
        {
          text: '음식',
          align: 'left',
          sortable: false,
          value: 'name',
        },
        { text: '칼로리', value: 'calory' },
        { text: '탄수화물 (g)', value: 'carb' },
        { text: '단백질 (g)', value: 'protein' },
        { text: '지방 (g)', value: 'fat' },
        { text: '', value: 'action', sortable: false },
      ],
      types: [
        { text: '일반', value: 1, },
        { text: '보충제', value: 2, },
      ],
      editedIndex: -1,
      editedItem: {
        name: '',
        amount: 1,
        fat: 0,
        protein: 0,
        carb: 0,
        calory: 0,
      },
      defaultItem: {
        name: '',
        amount: 1,
        fat: 0,
        protein: 0,
        carb: 0,
        calory: 0,
      },
      file: null,
      selectedSheet: 'Sheet1',
      imported_data: [],
      export_data: [],
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
      lvl: "lvl",

    })
  },
  created: async function() {
    this.loading = true;
  },
  mounted: async function() {
    if ( firebase.auth().currentUser ) {
      // 이전 정보가 있는지 확인
      const id_token = await firebase.auth().currentUser.getIdToken();
      const user = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/admin/user/list', {
        params: { email: this.user.data.email },
        headers: {'id_token': id_token},
      });

      const raw_food = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/food/', {
        params: { email: this.user.data.email },
        headers: {'id_token': id_token},
      });

      if (raw_food.data.length>0) {
        this.food_list = raw_food.data;
      }

      this.loading = false;
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
  },
  methods: {
    async upload_excel(e) {
      if ( !this.file ) {
        return alert('파일을 선택해 주십시오.');
      }
      let f = this.file;
      let reader = new FileReader();
      let obj = this;
      reader.onload = function(e) {
        let data = new Uint8Array(e.target.result);
        let workbook = xlsx_read(data, {type: 'array'});
        let sheetName = workbook.SheetNames[0];
        let worksheet = workbook.Sheets[sheetName];
        let json_worksheet = xlsx_utils.sheet_to_json(worksheet);

        for ( let each in json_worksheet ) {
          let temp  = {type:'', name: '', amount: 1, fat: 0, protein: 0, carb: 0, calory: 0};

          if ( json_worksheet[each]['음식구분'] == '일반' ) {
            temp.type = '1';
          } else if ( json_worksheet[each]['음식구분'] == '보충제' ) {
            temp.type = '2';
          } else {
            temp.type = json_worksheet[each]['음식구분'];
          }
          temp.name = json_worksheet[each]['음식'];
          temp.calory = json_worksheet[each]['칼로리'];
          temp.carb = json_worksheet[each]['탄수화물(g)'];
          temp.protein = json_worksheet[each]['단백질(g)'];
          temp.fat = json_worksheet[each]['지방(g)'];

          obj.imported_data.push(temp);
        }
      }
      reader.readAsArrayBuffer(f);
      this.food_list = this.imported_data

      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/food/addImport', {
        email: this.user.data.email,
        data: this.imported_data
      }, {
        headers: {'id_token': id_token},
      })

      this.file = null;
      this.imported_data = [];
    },
    async download_excel(e) {
      let temp_array = ['음식구분', '음식', '칼로리', '탄수화물(g)', '단백질(g)', '지방(g)'];
      this.export_data.push(temp_array);
      for ( let each of this.food_list ) {
        temp_array = [];
        temp_array.push(each.type);
        temp_array.push(each.name);
        temp_array.push(each.calory);
        temp_array.push(each.carb);
        temp_array.push(each.protein);
        temp_array.push(each.fat);

        this.export_data.push(temp_array)
      }

      let wb = xlsx_utils.book_new();
      let newWorksheet = xlsx_utils.aoa_to_sheet(this.export_data);
      xlsx_utils.book_append_sheet(wb, newWorksheet, 'Sheet1');
      let download_filename = 'weight-history('+this.user.data.email+').xlsx';
      xlsx_write(wb, download_filename);

      this.export_data = [];
    },
    async delete_all() {
      if ( confirm("삭제하기 전에 데이터 보관을 위해 XLSX 내보내기를 실행합니다. 저장하시겠습니까?") ) {
        this.download_excel()
      }
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/food/delall', {
        email: this.user.data.email,
      }, {
        headers: {'id_token': id_token},
      });
      this.food_list = [];
    },
    close: function () {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
    save: async function () {
      if (this.editedIndex > -1) {
        Object.assign(this.food_list[this.editedIndex], this.editedItem)
      } else {
        this.food_list.push(this.editedItem)
      }

      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/food/', {
        email: this.user.data.email,
        food: this.editedItem,
      }, {
        headers: {'id_token': id_token},
      });

      this.close()
    },
    deleteItem: async function (item) {
      const index = this.food_list.indexOf(item)

      const del_id = this.food_list[index]._id;

      confirm('삭제하시겠습니까?') && this.food_list.splice(index, 1);

      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.delete(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/food/', {
          data: {
            email: this.user.data.email,
            food_id: del_id,
          },
          headers: {'id_token': id_token},
      });
    },

  }
}
</script>

<style scoped>


.desc {
  margin-top:10px;
}

.headline {
  font-size: 1.3rem !important;
}

.desc_title {
  font-size:1.1em;
}

</style>
